<template>
  <div class="eventInfoPageWrap">
    <m-layout-bar>
      <div
        class="eventInfoWrap"
        :style="{background:'url('+bgImgUrl+')',backgroundSize:'100% 100%'}"
      >
        <div class="titleWrap">
          <img :src="require('../assets/image/eventInfo/titleLog.png')" alt />
          <!-- <span class="title">活动资讯</span>
          <img class="titleLog" :src="require('../assets/image/eventInfo/titleLogo.jpg')" alt />
          <img class="subtitle" :src="require('../assets/image/eventInfo/subtitle.jpg')" alt />-->
        </div>
        <div class="content">
          <div class="fixedMenus" :class="barFixed?'show':''">
            <router-link
              class="fixedMenu announcement trans"
              tag="div"
              to="/eventInfo/announcement?active=0"
              :class="{'active':activeIndex==='0'}"
              data-name="announcement"
              data-index="0"
              @click="clickMenuItem"
            >
              <!-- <img :src="require('../assets/image/stay/star.png')" alt /> -->
              通知公告
            </router-link>
            <router-link
              class="fixedMenu discount trans"
              tag="div"
              to="/eventInfo/discount?active=1"
              :class="{'active':activeIndex==='1'}"
              data-name="discount"
              data-index="1"
              @click="clickMenuItem"
            >
              <!-- <img :src="require('../assets/image/stay/star.png')" alt /> -->
              优惠活动
            </router-link>
            <router-link
              class="fixedMenu travel trans"
              tag="div"
              to="/eventInfo/travel?active=3"
              :class="{'active':activeIndex==='2'}"
              data-name="travel"
              data-index="2"
              @click="clickMenuItem"
            >
              <!-- <img :src="require('../assets/image/stay/star.png')" alt /> -->
              旅游资讯
            </router-link>
          </div>
          <!-- shadowBox_center -->
          <div class="tabs" :style="{background:'url('+menuUrl+')',backgroundSize:'100% 100%'}">
            <div class="bg">
              <span class="tab announcement">通知公告</span>
              <span class="tab discount">优惠活动</span>
              <span class="tab travel">旅游资讯</span>
            </div>
            <!-- 通知公告 -->
            <router-link
              class="tab announcement"
              :class="{'active':activeIndex==='0'}"
              data-name="announcement"
              data-index="0"
              to="/eventInfo/announcement?active=1"
              @click="clickMenuItem"
            >
              通知公告
              <!-- <img :src="require('../assets/image/eventInfo/m_1.png')" alt /> -->
            </router-link>
            <!-- 优惠活动 -->
            <router-link
              class="tab discount"
              :class="{'active':activeIndex==='1'}"
              to="/eventInfo/discount?active=2"
              data-name="discount"
              data-index="1"
              @click="clickMenuItem"
            >
              优惠活动
              <!-- <img :src="require('../assets/image/eventInfo/m_2.png')" alt /> -->
            </router-link>

            <!-- 旅游资讯 -->
            <router-link
              class="tab travel"
              :class="{'active':activeIndex==='2'}"
              to="/eventInfo/travel?active=3"
              data-name="travel"
              data-index="2"
              @click="clickMenuItem"
            >
              旅游资讯
              <!-- <img :src="require('../assets/image/eventInfo/m_3.png')" alt /> -->
            </router-link>
            <!-- </div> -->
          </div>
          <div id="tabContent" class="tabDetail">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";
export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      menuUrl: require("../assets/image/eventInfo/menuBg.png"),
      barFixed: false,
      bgImgUrl: require("../assets/image/company/bg.png"),
      activeName: "",
      activeIndex: "0"
    };
  },
  created() {
    //    this.$nextTick(function() {
    //     console.log("active",active);
    // let active = this.$route.query.active;
    // console.log("active1",active);
    //   if(parseInt(active).toString()==="NaN") return;
    //   this.activeIndex = active;
    //   });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    let path = this.$route.path;
    if (path.indexOf("announcement") > 0) {
      this.activeIndex = "0";
    } else if (path.indexOf("discount") > 0) {
      this.activeIndex = "1";
    } else if (path.indexOf("travel") > 0) {
      this.activeIndex = "2";
    }
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!document.querySelector("#tabContent")) return;
      let distOffsetTop = document.querySelector("#tabContent").offsetTop;

      if (parseInt(distOffsetTop).toString() === "NaN") {
        return;
      }

      if (scrollTop > distOffsetTop - 232) {
        this.barFixed = true;
      } else {
        this.barFixed = false;
      }

      // console.log("window scrollTop:", scrollTop);
      // console.log("目标 distOffsetTop:", distOffsetTop);
      // console.log("fixed标识：", this.barFixed);
    },

    clickMenuItem(e) {
      if (!e.currentTarget.getAttributeNode("data-index")) return;
      this.activeIndex = e.currentTarget.getAttributeNode("data-index").value;
      if (!this.activeIndex) {
        this.activeIndex = "";
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
@import "src/assets/styles/announcement/announcement.scss";

.eventInfoWrap {
  border: 1px solid transparent;
  .titleWrap {
    width: 1400px;
    margin: 33px auto 0 auto;
    > .title {
      font-size: 37px;
      font-weight: bold;
      margin-right: 30px;
    }
    .titleLog {
      width: 89px;
      height: 91px;
      vertical-align: bottom;
    }
    .subtitle {
      display: block;
      margin-top: 20px;
    }
  }
  .content {
    width: 1400px;
    margin: 53px auto 50px auto;
    border: 1px solid transparent;

    > .fixedMenus {
      position: fixed;
      top: 120px;
      width: 1400px;
      z-index: 99998;
      display: none;
      margin: 0 auto;

      &.show {
        display: block;
      }
      > .fixedMenu {
        display: inline-block;
        width: 466px;
        height: 50px;
        line-height: 50px;
        background: $font-grayDarkD;
        font-size: 27px;
        font-weight: bold;
        color: $main-white;
        text-align: center;
        border-right: 1px solid $font-grayDark;

        > img {
          margin-right: 10px;
        }
        &.announcement.active {
          background: $main-red;
        }

        &.discount.active {
          background: $main-yellow;
        }
        &.travel.active {
          background: $main-blue;
        }

        &.announcement {
          border-bottom: 10px solid $main-red;
          // border-bottom-left-radius: 10px;
          &:hover {
            background: $main-red;
          }
        }
        &.discount {
          border-bottom: 10px solid $main-yellow;
          &:hover {
            background: $main-yellow;
          }
        }
        &.travel {
          border-bottom: 10px solid $main-blue;
          // border-bottom-right-radius: 10px;
          &:hover {
            background: $main-blue;
          }
        }
      }
      > .fixedMenu:nth-of-type(3) {
        border-right: 0px;
        // margin-left: 5px;
      }
    }

    > .tabs {
      position: relative;
      width: 100%;
      height: 120px;
      // background: $main-white;
      // color: $main-gray;
      // border-radius: 50px;
      transition: all 0.2s ease-in-out;

      .tab {
        position: absolute;
        display: inline-block;
        width: 400px;
        height: 120px;
        top: 0;
        margin-right: 100px;
        background: $main-blue;
        border-radius: 80px;
        text-align: center;
        line-height: 120px;
        font-size: 44px;
        font-weight: bold;

        opacity: 1;
      }

      .tab.announcement {
        // margin-left: 1px;
        left: 0;
      }

      .tab.discount {
        // margin-left: 1px;
        left: 500px;
      }
      .tab.travel {
        // margin-left: 1px;
        right: 0;
      }
      .tab:last-of-type {
        margin-right: 0;
      }
      > .tab {
        color: #fff;
        opacity: 0;
      }
      > .tab.announcement.active {
        // margin-left: 1px;
        // display:inline-block;
        opacity: 1;
      }

      > .tab.discount.active {
        // margin-left: 1px;
        // display:inline-block;
        opacity: 1;
      }
      > .tab.travel.active {
        // margin-left: 1px;
        // display:inline-block;
        opacity: 1;
      }

      > .bg {
        width: 100%;
        height: 120px;
        > .tab {
          z-index: 0;
          color: $main-gray;
          background: transparent;
        }
      }
    }
  }
}
</style>
